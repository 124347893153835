<template>
	<v-card class="mt-4" rounded="xl">
		<v-card-title @click="collapsed = !collapsed" style="cursor: pointer;">
			<v-row>
				<v-col cols="12" md="8" sm="12">
					<span style="word-break: normal">[{{ university.acronym }}] {{ university.name }} </span>
				</v-col>
				<v-col cols="12" md="4" sm="12" class="d-flex justify-space-between">
					<span><v-icon>mdi-school</v-icon> {{ university.studentsNum }}</span>
					<v-btn icon :to="{ name: 'StudiesUniversity', params: { id: university.id } }">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-card-title>

		<v-card-text v-if="!collapsed" class="px-2">
			<FacultyCard v-for="faculty in university.faculties" :key="faculty.id" :faculty="faculty" />

			<v-alert v-if="!university.faculties.length">
				<h4>No faculties for this university</h4>
			</v-alert>
			<v-btn color="primary" @click="goToStudiesFacultyAdd()" class="mt-4">
				Add new faculty
			</v-btn>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'
import FacultyCard from '@/components/faculties/FacultyCard.vue'

export default {
	name: 'UniversityCard',
	props: {
		university: {
			type: Object,
			required: true
		}
	},
	components: {
		FacultyCard
	},
	data() {
		return {
			collapsed: true
		}
	},
	methods: {
		goToStudiesFacultyAdd() {
			this.fetchUniversity(this.university.id).then((data) => {
				if (data.success) {
					this.$router.push({ name: 'StudiesFacultyAdd' })
				}
			})
		},
		...mapActions('universities', ['fetchUniversity'])
	}
}
</script>

<style lang="scss" scoped></style>
